import { createApp } from 'vue'
import BootstrapVue3 from 'bootstrap-vue-3'
import App from './App.vue'



import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import { library } from "@fortawesome/fontawesome-svg-core"
import { faPhone,faEnvelope,faLocationDot,faSearchPlus } from "@fortawesome/free-solid-svg-icons"
import {faFacebookSquare, faInstagramSquare,faInstagram,faFacebookF,faWhatsapp} from "@fortawesome/free-brands-svg-icons"
// import VueEasyLightbox from 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.es5.esm.min.js'
// import 'vue-easy-lightbox/external-css/vue-easy-lightbox.css'
import VueEasyLightbox from 'vue-easy-lightbox'



library.add(faPhone,faEnvelope,faFacebookSquare,faInstagramSquare,faLocationDot,faSearchPlus,faFacebookF,faInstagram,faWhatsapp);
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const app = createApp(App)
app.use(BootstrapVue3)
app.use(VueEasyLightbox)
app.component("font-awesome-icon", FontAwesomeIcon)
app.mount('#app')




