<template>
  <nav class="social">
    <ul>
      <li>
        <a href="https://facebook.com/" target="blank"
          ><font-awesome-icon
            :icon="{ prefix: 'fab', iconName: 'facebook-f' }"
          /><span> Facebook</span>
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/chirpychirpstay/" target="blank"
          ><font-awesome-icon
            :icon="{ prefix: 'fab', iconName: 'instagram' }"
          /><span>Instagram</span>
        </a>
      </li>
      <li class="hidden-whatsapp-mobile">
        <a href="https://web.whatsapp.com/send?phone=9004384358"
          ><font-awesome-icon
            :icon="{ prefix: 'fab', iconName: 'whatsapp' }"
          /><span>Whatsapp</span>
        </a>
      </li>
      <li class="hidden-whatsapp">
        <a href="https://api.whatsapp.com/send?phone=919004384358"
          ><font-awesome-icon
            :icon="{ prefix: 'fab', iconName: 'whatsapp' }"
          /><span>Whatsapp</span>
        </a>
      </li>
    </ul>
  </nav>

  <a href="#" class="top"> &#8593;</a>

  <footer>
    <b-container>
      <b-row>
        <b-col>Copyright © {{ dtYear }}. All Rights Reserved.</b-col>
      </b-row>
    </b-container>
  </footer>
</template>
<script>
export default {
  name: "FooterArea",
  props: {
    msg: String,
  },

  data() {
    return {
      dtYear: new Date().getFullYear(),
    };
  },
};
</script>
<style scoped>
footer {
  background: #00832e;
  text-align: center;
  padding: 20px 0;
  color: #fff;
}
.social {
  position: fixed;
  top: 60%;
  right: 0;
  z-index: 2;
}
.social ul {
  padding: 0px;
  /* -webkit-transform: translate(0,-270px);
  -moz-transform: translate(0,-270px);
  -ms-transform: translate(0,-270px);
  -o-transform: translate(0,-270px); */
  /* transform: translate(130,0); */

  margin: 0;
}
.social ul li {
  display: block;
  margin: 5px;
  background: rgba(0, 0, 0, 0.36);
  transform: translate(87px, 0);
  text-align: left;
  padding: 10px;
  overflow: hidden;
  -webkit-border-radius: 30px 0 0 30px;
  -moz-border-radius: 30px 0 0 30px;
  border-radius: 30px 0 0 30px;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}
.social ul li:hover {
  /* -webkit-transform: translate(110px, 0);
  -moz-transform: translate(110px, 0);
  -ms-transform: translate(110px, 0);
  -o-transform: translate(110px, 0); */
  transform: translate(0, 0);
  background: rgba(255, 255, 255, 0.4);
}
.social ul li a{
  text-decoration: none;
  color: #fff;
}
.social ul li:hover a {
  color: #fff;
}
.social ul li:hover:nth-last-child(1) {
  color: #fff;
  background: #25D366;}
.social ul li:hover:nth-last-child(2) {
  color: #fff;
  background: #25D366;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
  
}
.social ul li:hover:nth-last-child(3) {
  color: #fff;
  background: #833AB4;
}.social ul li:hover:nth-last-child(4) {
  color: #fff;
  background: #4267B2;
}
.social ul li svg {
  float: left;
  margin-right: 10px;
  color: #000;
  background: #fff;
  padding: 10px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 20px;
  background: #ffffff;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}

.top {
  --offset: 50px;
  position: sticky;
  bottom: 20px;
  margin-right: 10px;
  place-self: end;
  bottom: 0;

  /* visual styling */
  text-decoration: none;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-family: sans-serif;
  color: #fff;
  background: #000;
  border-radius: 50%;
  white-space: nowrap;
  float: right;
}

@media (max-width: 575.98px) { 
  .hidden-whatsapp-mobile{
    display: none !important;
  }
}
  @media (min-width: 576px) { 
  .hidden-whatsapp{
    display: none !important;
  }
 }
</style>

