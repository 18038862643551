<template>
  <section class="about-area">
    <b-container class="bv-example-row">
      <b-row class="justify-content-lg-center">
        <b-col col lg="5" cols="12">
          <img src="../assets/aboutimg.png" class="img" />
        </b-col>
        <b-col col lg="6" cols="12" class="about-text">
          <b-col cols="12"
            ><h2>Welcome To <b>Chirpy Chirps</b></h2>
            <div class="elementor-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                viewBox="0 0 53 7"
                xml:space="preserve"
              >
                <g class="nc-icon-wrapper">
                  <path
                    class="st0"
                    d="M0 2.59h.44c.77 0 2.82-2.67 4.58.96 1.23 2.53 3.66 2.4 5.31 0 1.65-2.4 4.26-2.7 6.53 0 1.67 1.99 3.83 2.86 5.53 0 .45-.75 3-3.96 6.03 0 3.17 4.15 5.83 0 5.83 0s2.68-4.33 5.86 0c3.02 4.11 5.25 0 5.25 0s2.31-4.32 5.12 0C51.2 4.64 53 4.56 53 4.56"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-miterlimit="100"
                  ></path>
                </g>
              </svg>
            </div>
          </b-col>
          <p>
            We are home away from home for your pets while you are away.
We care !!! so we treat them just as our own 🥰
          <br>
         We provide care as per pet requirements and maintain proper hygiene.<br>
          We take utmost care for all safety measures  and keep them air conditioned.<br>
         We are connected to all the vets and follow all veterinary protocols.<br>
         Its not just lodging and boarding its a fun enrichment for your pets and a chance to socialise with others 🦜🪶🕊</p>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "AboutArea",
  props: {
    msg: String,
  },
 
};
</script>

<style scoped>
.about-area {
  background-image: url(../assets/about-bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  padding: 100px 0;
}
.about-text h2 {
  font-size: 3.125rem;
  line-height: 1em;
  transform: rotate(0deg);
  text-align: center;
  font-weight: bold;
  color: #000;
}
.about-text h2 b {
  color: #00832e;
}
.elementor-icon {
  vertical-align: middle;
  max-width: 110px;
  max-height: 110px;
  color: #00832e;
  border-color: #00832e;
  margin: 0 auto 30px auto;
}
.img {
  width: 100%;
}
.about-text {
  line-height: 23px;
  color: #707070;
  padding: 50px 0 50px 50px;
  line-height: 1.7;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  text-align: center;
  font-size: 18px;
}

@media (min-width: 320px) and (max-width: 575.98px) {
  .about-text {
    padding: 0px 30px;
    background: #fff;
  }
  .about-area {
    padding: 50px 0;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .about-text {
    padding: 0px 30px;
    background: #fff;
  }
  .about-area {
    padding: 50px 0;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .about-text {
    padding: 0px 30px;
    background: #fff;
  }
  .about-area {
    padding: 50px 0;
  }
}
</style>