<template>
    <section class="servise-area">
        <b-container>
            <b-row class="justify-content-lg-center">
                <b-col col lg="12" cols="12">
                    <h2>Our servics</h2>
                     <div class="elementor-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 53 7" xml:space="preserve">
                        <g class="nc-icon-wrapper"><path class="st0" d="M0 2.59h.44c.77 0 2.82-2.67 4.58.96 1.23 2.53 3.66 2.4 5.31 0 1.65-2.4 4.26-2.7 6.53 0 1.67 1.99 3.83 2.86 5.53 0 .45-.75 3-3.96 6.03 0 3.17 4.15 5.83 0 5.83 0s2.68-4.33 5.86 0c3.02 4.11 5.25 0 5.25 0s2.31-4.32 5.12 0C51.2 4.64 53 4.56 53 4.56" fill="none" stroke="currentColor" stroke-width="2" stroke-miterlimit="100"></path></g>
                        </svg>
                    </div>
                </b-col>
                <b-col col lg="3" v-for="serviceDt in servicesData"  :key="serviceDt">
                    <div class="servics-box">
                       <img :src="getImgUrl(serviceDt.imgUrl)" />
                       <h3>{{serviceDt.head}}</h3>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>


<script>
export default {
  name: "ServiceArea" ,

  data () {
        return {
            servicesData: [
                {head:"BOARDING", imgUrl: 'service1.svg', },
                {head:"DAY CARE", imgUrl: 'service2.svg',  },
                {head:"24X7 CARE", imgUrl: 'service3.svg', }, 
                {head:"TRAINING", imgUrl: 'service4.svg', },
                {head:"INDIVIDUALISED CARE", imgUrl: 'service5.svg', },
                {head:"PET PLAY WITH OTHERS", imgUrl: 'service6.svg', },
                {head:"REGULAR UPDATES", imgUrl: 'service7.svg', },
                // {head:"VIDEO CALL EVERY WEEKEND", imgUrl: 'service8.svg', },              
            ]
        }
      },

 methods: {
  getImgUrl(pic) {
    return require('../assets/'+pic)
  },
}
  

}
</script>


<style scoped>
.servise-area{background-image: url(../assets/servics-bg.png);
    background-position: center center; background-size: cover;
    background-repeat: no-repeat;padding: 50px 0; 
}
.servise-area h2{font-size: 3.125rem;line-height: 1em; transform: rotate(0deg); text-align: center; font-weight: bold; color: #fff;}
.servics-box{border: 1px solid rgb(203, 203, 203); padding: 20px; text-align: center; color: #fff;
border-radius: 100px 50px 120px 60px; margin: 15px 0;
}
.servics-box:hover{background: #00832e; transition: ease-in 0.5s; border: transparent; cursor: pointer;}
.servics-box img{width: 50px; height: 50px;}
.servics-box h3{margin: 10px 0 0 0; text-transform: uppercase; font-size: 18px;}
.elementor-icon {
    vertical-align: middle;
    max-width: 110px;
    max-height: 110px;color: #fff;
    border-color: #fff;margin:0 auto 30px auto ;
}
</style>