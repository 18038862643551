<template>



  <section class="banner-area">
    <div class="logo-area"><img src="../assets/logo1.svg" class="logo" /></div>
    <!-- <h1 class="text-uppercase">Chirpy Chirps</h1> -->
    <div class="bird tm-animation-floating">
      <img src="../assets/bird.png" />
    </div>
    <b-container class="bv-example-row bv-example-row-flex-cols">
      <b-row class="justify-content-lg-center">
        <b-col col lg="10" md="12">
          <div class="booking-area">
            <b-row class="justify-content-lg-center">
              <b-col col lg="2" class="check-box" @click="openStartCalneder" v-if="!showStartDatePkr">
                <span class="lbl_input">CHECK-IN</span>
                <p class="date-code">
                  {{ startDt }} {{ startMonth }} {{ startYear }}
                </p>
                <p class="code">{{ startDay }}</p>
              </b-col>
              <b-col v-if="showStartDatePkr" col lg="2" class="chech-in-cld">
                <Datepicker :disabledDates="startDisabledDates" :inline="true" v-model="startDate"
                  @selected="handelStartDateSelected"></Datepicker>
              </b-col>
              <b-col col lg="2" class="check-box ml15" @click="openEndCalender" v-if="!showEndDatePkr">
                <span class="lbl_input">CHECK-OUT</span>
                <p class="date-code">
                  {{ endDt }} {{ endMonth }} {{ endYear }}
                </p>
                <p class="code">{{ endDay }}</p>
              </b-col>
              <b-col col lg="2" v-if="showEndDatePkr" class="chech-out-cld">
                <Datepicker :disabledDates="checkoutDisableDate" :inline="true" v-model="endDate"
                  @selected="handelEndDateSelected"></Datepicker>
              </b-col>

              <b-col col lg="7" md="12" cols="12">
                <div class="choose-box">
                  <span class="text-uppercase">choose the type of pet</span>

                  <ul>
                    <li v-for="pet in pets" :key="pet.id" name="petsselected">
                      <input type="checkbox" v-bind:id="pet.id" v-model="checkedPets" :value="pet.petsName" />
                      <label v-bind:for="pet.id"><img v-bind:src="getImgUrl(pet.imgurl)" /></label>
                      <span>{{ pet.petsName }}</span>
                    </li>
                  </ul>
                </div>
              </b-col>
            </b-row>

            <b-row class="justify-content-lg-center" v-if="showfinalbtn">
              <b-col col lg="3" class="mt-3" cols="6">
                <div>
                  <span class="ft16 text-uppercase"> Name</span>
                  <input type="text" class="ai-input" placeholder="Enter your name" v-model="name" />
                </div>
              </b-col>
              <b-col col lg="3" class="mt-3" cols="6">
                <div>
                  <span class="ft16 text-uppercase"> Mobile Number</span>
                  <input type="text" class="ai-input" placeholder="Enter your mobile number" v-model="mobile" />
                </div>
              </b-col>
              <b-col col lg="4" class="mt-3" cols="12">
                <span class="ft16 text-uppercase"> Additional information</span>
                <textarea rows="3" type="text" class="ai-textarea" placeholder="Enter your additional information"
                  v-model="addisitionDetail"></textarea>


              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <p class="error-massege">{{ errorMassege }}</p>
              </b-col>
            </b-row>

            <div class="position-reative">
              <a class="book-now-btn" @click="showFinalBtn" v-if="!showfinalbtn">
                Book Now
              </a>

              <div v-if="showfinalbtn">
                <div v-if="!isSubmiting">
                  <a class="book-now-btn fbn-btn" @click="clickFinalBookBtn">
                    Submit
                  </a>

                  <a class="book-now-btn cancel-btn" @click="showBookNowBtn">
                    Cancel
                  </a>
                </div>
                <div v-else>
                  <span class="loading-text">Processing...</span>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-modal centered v-model="finalModalShow" ok-only class="text-center">

        <h2>Thank you!</h2>
        <h3>{{ name }}</h3>
        <p>
          We have received your details, Our team will contact you
          shortly.
        </p>
        <div>
          Please connect us on
          <a v-if="!isMobile()" class="whatsapp-btn-popup" href="https://web.whatsapp.com/send?phone=9004384358"
            target="blank">
            <img src="../assets/whatsapp.svg" />
          </a>

          <a v-if="isMobile()" class="whatsapp-btn-popup" href="https://api.whatsapp.com/send?phone=919004384358"
            target="blank">
            <img src="../assets/whatsapp.svg" /></a>
          <!-- <a href="https://www.instagram.com/chirpychirpstay/" target="blank">
                    <font-awesome-icon :icon="{prefix: 'fab', iconName: 'instagram-square'}"  /> Instagram
                    </a>  -->

          for instance response.
        </div>
      </b-modal>
    </b-container>
  </section>

  <!-- <div class="whatsapp-btn hidden-xs">
    <a href="https://web.whatsapp.com/send?phone=9004384358" target="blank">
      <img src="../assets/whatsapp.svg" />
    </a>
  </div>

  <div class="whatsapp-btn hidden-lg hidden-md hidden-sm">
    <a href="https://api.whatsapp.com/send?phone=919004384358" target="blank">
      <img src="../assets/whatsapp.svg"
    /></a>
  </div> -->
</template>



<script>
//  import Datepicker from '@vuepic/vue-datepicker';
//  import '@vuepic/vue-datepicker/dist/main.css';

import Datepicker from "vuejs3-datepicker";
import axios from "axios";

export default {
  name: "BannerArea",
  props: {
    msg: String,
  },
  components: {
    Datepicker,

  },
  data() {
    return {
      errorMassege: "",
      isSubmiting: false,
      finalModalShow: false,
      addisitionDetail: "",
      mobile: "",
      name: "",
      checkedPets: [],
      pets: [
        { id: "cb1", petsName: "Birds", imgurl: "choose-icon1.jpg" },
        { id: "cb2", petsName: "Rabbit", imgurl: "choose-icon2.jpg" },
        { id: "cb3", petsName: "Turtle", imgurl: "choose-icon4.jpg" },
        { id: "cb4", petsName: "Hamster", imgurl: "choose-icon3.jpg" },
      ],
      showfinalbtn: false,
      startDisabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      checkoutDisableDate: {
        to: new Date(Date.now() - 8640000),
      },
      showStartDatePkr: false,
      showEndDatePkr: false,
      startDate: null,
      endDate: null,
      startMonth: "",
      startDt: "",
      startYear: "",
      startDay: "",
      endMonth: "",
      endDt: "",
      endYear: "",
      endDay: "",
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      days: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
    };
  },
  methods: {



    async consumeApi(api, data, headers) {
      let url = "";
      headers["Content-Type"] = api.contentType;
      url = "http://chirpychirps.com/api"
      const config = {
        method: api.method,
        mode: 'no-cors',
        url: url + api.url,
        headers: headers,
        withCredentials: false,
        credentials: 'same-origin',
        data: data,
      }

      try {
        const res = await axios(config)
        console.log(res)
        return res
      } catch (error) {
        console.log(error)
        return error.response
      }

    },


    isMobile() {
      if (screen.width <= 760) {
        return true;
      }
      else {
        return false;
      }
    },
    clearSelected() {
      (this.startDate = new Date().toISOString().substr(0, 10)),
        (this.endDate = new Date().toISOString().substr(0, 10)),
        this.startDateClick();
      this.endDateClick();
      this.checkedPets = [];
      this.name = "";
      this.mobile = "";
      this.addisitionDetail = "";
      this.showfinalbtn = false;
    },
   async clickFinalBookBtn() {
      var checkinDate =
        this.startDt + " " + this.startMonth + " " + this.startYear;
      var checkoutDate = this.endDt + " " + this.endMonth + " " + this.endYear;
      var selectedPets = this.checkedPets.join();
      this.errorMassege = "";

      if (this.name == "") {
        this.errorMassege = "*Please fill your name | ";
      }
      if (
        this.mobile == "" ||
        this.mobile.length != 10 ||
        !this.mobile.match("[0-9]{10}")
      ) {
        this.errorMassege += "*Please fill your 10 digit mobile number | ";
      }
      if (this.checkedPets.length == 0) {
        this.errorMassege += "*Please select atlist one pet";
      }

      if (this.errorMassege != "") {
        return;
      }
      this.isSubmiting = true;
      try {
        var api = { url: "/booknow", contentType: "application/json", method: "post" }
        var body = {
          username: this.name,
          mobileno: this.mobile,
          pets: selectedPets,
          checkin: checkinDate,
          checkout: checkoutDate,
          additonaldetails: this.addisitionDetail,
        }
       await this.consumeApi(api, body, {})
        this.isSubmiting = false;
        this.clearSelected();
        this.finalModalShow = true;
    

      } catch (error) {
        console.log({ error });
      }
    },
    showFinalBtn() {
      this.showfinalbtn = true;
    },
    showBookNowBtn() {
      this.showfinalbtn = false;
    },
    startDateClick() {
      let dt = new Date(this.startDate);
      this.checkoutDisableDate = { to: new Date(dt - 8640000) };

      this.startMonth = this.monthNames[dt.getMonth()];
      this.startDt = dt.getDate();
      this.startYear = dt.getFullYear();
      this.startDay = this.days[dt.getDay()];
    },
    endDateClick() {
      let dt = new Date(this.endDate);
      this.endMonth = this.monthNames[dt.getMonth()];
      this.endDt = dt.getDate();
      this.endYear = dt.getFullYear();
      this.endDay = this.days[dt.getDay()];
    },
    handelStartDateSelected(startDateValue) {
      this.startDate = startDateValue;
      this.showStartDatePkr = false;
      this.startDateClick();
      let endDt = new Date(this.endDate);
      if (this.startDate >= endDt) {
        this.endDate = startDateValue;
        this.endDateClick();
      }
    },
    handelEndDateSelected(endDateValue) {
      this.endDate = endDateValue;
      this.showEndDatePkr = false;
      this.endDateClick();
    },
    openStartCalneder() {
      if (!this.showEndDatePkr) {
        this.showStartDatePkr = true;
      }
    },
    openEndCalender() {
      if (!this.showStartDatePkr) {
        this.showEndDatePkr = true;
      }
    },
    getImgUrl(pic) {
      return require("../assets/" + pic);
    },
  },
  mounted() {
    (this.startDate = new Date().toISOString().substr(0, 10)),
      (this.endDate = new Date().toISOString().substr(0, 10)),
      this.startDateClick();
    this.endDateClick();
  },
};
</script>

<style scoped>
.error-massege {
  color: red;
  font-weight: 700;
}

.loading-text {
  color: #00832e;
  font-weight: bold;
  font-size: 1.3rem;
}

.logo-area {
  margin: auto;
  width: 200px;
  height: 200px;
  background: #c9e265;
  border-radius: 50%;
  margin-bottom: 30px;
}

.logo {
  width: 200px;
  height: 100%;
  margin: auto;
  text-align: center;
}

.dp__input {
  background: transparent !important;
  border: none;
  height: 114px;
  top: 0;
  position: absolute;
  width: 150px;
  padding: 0;
}

.dp__theme_light {
  left: 326px !important;
}

.whatsapp-btn {
  position: fixed;
  left: 14px;
  bottom: 40px;
  width: 45px;
  height: 45px;
  line-height: 45px;
  background-color: transparent;
  color: white;
  text-align: center;
  z-index: 1;
}

.chech-in-cld .vuejs3-datepicker {
  position: absolute;
  display: inline-block;
  top: 0px !important;
  left: 0px;
  z-index: 2;
}

.chech-out-cld .vuejs3-datepicker {
  position: absolute;
  display: inline-block;
  top: 0px !important;
  left: 100px;
  z-index: 2;
}

.whatsapp-btn img {
  width: 50px;
  height: 50px;
}

.whatsapp-btn-popup img {
  width: 30px;
  height: 30px;
}

.bird {
  position: absolute;
  z-index: 1;
}

.tm-animation-floating {
  -webkit-animation-name: tm-animation-floating;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-name: Floating;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: ease-in-out;
}

.ft16 {
  font-size: 16px;
  font-weight: bold;
}

.ai-input {
  width: 100%;
  border: 1px solid #cac4c4;
  height: 40px;
  padding: 0 10px;
  margin-bottom: 20px;
}

.ai-input:focus {
  border: 1px solid #00832e;
  outline: none;
}

.ai-textarea {
  width: 100%;
  border: 1px solid #cac4c4;
  height: auto;
  padding: 0 10px;
  margin-bottom: 20px;
}

.ai-textarea:focus {
  border: 1px solid #00832e;
  outline: none;
}

@-webkit-keyframes tm-animation-floating {
  from {
    -webkit-transform: translate(0, 0px);
  }

  65% {
    -webkit-transform: translate(15px, 0px);
  }

  to {
    -webkit-transform: translate(0, 0px);
  }
}

@-moz-keyframes tm-animation-floating {
  from {
    -moz-transform: translate(0, 0px);
  }

  65% {
    -moz-transform: translate(15px, 0px);
  }

  to {
    -moz-transform: translate(0, 0px);
  }
}

.book-now-btn {
  background: #00832e;
  border-radius: 20px;
  position: absolute;
  color: #fff;
  margin: 15px 0 0 0;
  padding: 10px 50px;
  left: 42%;
  cursor: pointer;
  text-decoration: none;
}

.fbn-btn {
  left: 30%;
}

.cancel-btn {
  left: 50%;
  background-color: red;
}

.banner-area {
  background: url(../assets/slider-1.jpg);
  padding: 40px 0 100px 0;
  background-size: 100%;
  background-attachment: fixed;
}

.banner-area h1 {
  color: #fff;
  text-align: center;
}

.booking-area {
  background: #fff;
  border-radius: 30px;
  box-shadow: 5px 5px 5px #000;
  padding: 40px 20px 40px 20px;
  margin: 50px 0 0 0;
  text-align: center;
  width: 100%;
  margin: auto;
  position: relative;
}

.check-box {
  padding: 35px 10px;
  background: #f1f1f1;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.ml15 {
  margin-left: 15px;
}

.lbl_input {
  font-size: 16px;
}

.date-code {
  font-size: 18px;
  font-weight: bold;
}

.lbl_input:after {
  border: solid #00832e;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  content: "";
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  vertical-align: top;
  margin: 3px 0 0 8px;
  transition: all 0.3s ease-in-out;
}

.choose-box {
  padding: 10px;
  background: #f1f1f1;
  border-radius: 5px;
}

.choose-box span {
  font-size: 16px;
  font-weight: bold;
}

.check-box p {
  margin: 0px;
}

.choose-box ul {
  list-style-type: none;
  margin: 8px 0 0 0;
  padding: 0px;
}

.choose-box li {
  display: inline-block;
  margin: 0 8px;
}

.choose-box li span {
  font-size: 12px;
  text-transform: uppercase;
}

.choose-box input[type="checkbox"][id^="cb"] {
  display: none;
}

.choose-box label {
  border: 1px solid #fff;
  padding: 7px;
  display: block;
  position: relative;
  /* margin: 10px; */
  cursor: pointer;
}

.choose-box label:before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 23px;
  transition-duration: 0.4s;
  transform: scale(0);
}

.choose-box label img {
  height: 60px;
  width: 60px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
  border-radius: 50%;
}

.choose-box :checked+label {
  border-color: #ddd;
}

.choose-box :checked+label:before {
  content: "✓";
  background-color: grey;
  transform: scale(1);
}

.choose-box :checked+label img {
  transform: scale(0.9);
  box-shadow: 0 0 5px #333;
  z-index: -1;
}

@media (min-width: 320px) and (max-width: 575.98px) {
  .logo-area {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
  }

  .logo {
    width: 200px;
  }

  .book-now-btn {
    left: 30%;
  }

  .fbn-btn {
    left: 14%;
    padding: 10px 30px;
  }

  .cancel-btn {
    left: 54%;
    padding: 10px 30px;
  }

  .servise-area {
    padding: 50px 0 100px 0;
  }

  .check-box {
    margin-bottom: 15px;
    padding: 10px;
  }

  .choose-box li {
    margin: 0px 2px;
  }

  .bird {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .logo-area {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
  }

  .logo {
    width: 200px;
  }

  .book-now-btn {
    left: 30%;
  }

  .fbn-btn {
    left: 14%
  }

  .cancel-btn {
    left: 54%;
  }

  .servise-area {
    padding: 50px 0 100px 0;
  }

  .check-box {
    margin-bottom: 15px;
    padding: 10px;
  }

  .choose-box li {
    margin: 0px 2px;
  }

  .bird {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .book-now-btn {
    left: 39%;
  }

  .fbn-btn {
    left: 25%
  }

  .cancel-btn {
    left: 52%
  }

  .servise-area {
    padding: 50px 0 100px 0;
  }

  .check-box {
    margin-bottom: 15px;
    padding: 20px 10px;
  }

  .bird {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .choose-box li {
    margin: 0px;
  }

  .check-box {
    padding: 25px 10px;
  }

  .bird img {
    width: 68%;
    margin: 31% 0 0 0;
  }
}
</style>