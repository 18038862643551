<template>

  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <TopArea/>
  <BannerArea/>
  <AboutArea/>
  <ServiceArea/>
  <CountUs/>
  <GalleryArea/>
  <FooterArea/>
</template>

<script>
import TopArea from './components/TopArea.vue'
import BannerArea from './components/BannerArea.vue'
import AboutArea from './components/AboutArea.vue'
// import SliderArea from './components/SliderArea.vue'
import ServiceArea from './components/ServiceArea.vue'
import CountUs from './components/CountUs.vue'
import GalleryArea from './components/GalleryArea.vue'
import FooterArea from './components/FooterArea.vue'
export default {
  name: 'App',
  components: {
    TopArea,    
    BannerArea,
    AboutArea,
    ServiceArea,
    CountUs,
    GalleryArea,
    FooterArea
  }
}
</script>


<style>
#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
   scroll-behavior: smooth;
}
</style>
