<template>
<section class="gallery-area">
<b-container>
    <b-row>
        <b-col>
            <p class="p text-center">Photo Gallery</p>
            <h2>Looking & Smelling Great!</h2>
            <div class="elementor-icon">
                <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 53 7" xml:space="preserve">
                <g class="nc-icon-wrapper"><path class="st0" d="M0 2.59h.44c.77 0 2.82-2.67 4.58.96 1.23 2.53 3.66 2.4 5.31 0 1.65-2.4 4.26-2.7 6.53 0 1.67 1.99 3.83 2.86 5.53 0 .45-.75 3-3.96 6.03 0 3.17 4.15 5.83 0 5.83 0s2.68-4.33 5.86 0c3.02 4.11 5.25 0 5.25 0s2.31-4.32 5.12 0C51.2 4.64 53 4.56 53 4.56" fill="none" stroke="currentColor" stroke-width="2" stroke-miterlimit="100"></path></g>
                </svg>
            </div>
        </b-col>
    </b-row>
  <b-row>
    <b-col
      cols="6"
      lg="2" sm="4"
      v-for="(img, index) in imgs"
      :key="index"
      class="pic"
    >

    <div v-if="index==0">
      <div class="gcontainer" @click="() => showImg(0)">
        <img :src="img.pic1"/> 
        <div class="overlay"> <font-awesome-icon icon="search-plus" /></div> 
      </div>    
        <div class="gcontainer" @click="() => showImg(1)">
        <img :src="img.pic2"/> 
        <div class="overlay"> <font-awesome-icon icon="search-plus" /></div> 
      </div>  
    
  </div>
    
  <div v-else-if="index==1">
      <div class="gcontainer" @click="() => showImg(2)">
        <img :src="img.pic1" class="mt-80" /> 
        <div class="overlay"> <font-awesome-icon icon="search-plus" class="one" /></div> 
      </div>    
        <div class="gcontainer" @click="() => showImg(3)">
        <img :src="img.pic2"/> 
        <div class="overlay"> <font-awesome-icon icon="search-plus" /></div> 
      </div> 
    
  </div>

  <div v-else-if="index==2">
    <div class="gcontainer" @click="() => showImg(4)">
        <img :src="img.pic1" class="mt-160" /> 
        <div class="overlay"> <font-awesome-icon icon="search-plus" class="two" /></div> 
      </div>    
        <div class="gcontainer" @click="() => showImg(5)">
        <img :src="img.pic2" /> 
        <div class="overlay"> <font-awesome-icon icon="search-plus" /></div> 
      </div> 
  </div>

  <div v-else-if="index==3">
    <div class="gcontainer" @click="() => showImg(6)">
        <img :src="img.pic1" class="mt-160" /> 
        <div class="overlay"> <font-awesome-icon icon="search-plus" class="two"/></div> 
      </div>    
        <div class="gcontainer" @click="() => showImg(7)">
        <img :src="img.pic2"/> 
        <div class="overlay"> <font-awesome-icon icon="search-plus" /></div> 
      </div> 
  </div>
  <div v-else-if="index==4">
    <div class="gcontainer" @click="() => showImg(8)">
        <img :src="img.pic1" class="mt-80" /> 
        <div class="overlay"> <font-awesome-icon icon="search-plus" class="one"/></div> 
      </div>    
        <div class="gcontainer" @click="() => showImg(9)">
        <img :src="img.pic2"/> 
        <div class="overlay"> <font-awesome-icon icon="search-plus" /></div> 
      </div>  
  </div>
    
    <div v-else>
       <div class="gcontainer" @click="() => showImg(10)">
        <img :src="img.pic1"/> 
        <div class="overlay"> <font-awesome-icon icon="search-plus" /></div> 
      </div>    
        <div class="gcontainer" @click="() => showImg(11)">
        <img :src="img.pic2"/> 
        <div class="overlay"> <font-awesome-icon icon="search-plus" /></div> 
      </div>   
  </div>
      
    </b-col>
  </b-row>
  
</b-container>

  <vue-easy-lightbox
    :visible="visible"
    :imgs="imgs1"
    :index="index"
    @hide="handleHide"
  ></vue-easy-lightbox>
</section>
</template>


<script>
import VueEasyLightbox from "vue-easy-lightbox";
export default {
  name: "GalleryArea",
  props: {
    msg: String,
  },

  components: {
    VueEasyLightbox,
  },
  data() {
    return {
      visible: false,
      index: 0, // default: 0
      imgs: [],
      imgs1: [],
    };
  },
   mounted() {
    this.getImageData()
  },
  methods: {
    getImageData() {
      this.imgs1.push(require("../assets/galler-1.jpg"));
      this.imgs1.push(require("../assets/galler-2.jpg"));
      this.imgs1.push(require("../assets/galler-3.jpg"));
      this.imgs1.push(require("../assets/galler-4.jpg"));
      this.imgs1.push(require("../assets/galler-5.jpg"));
      this.imgs1.push(require("../assets/galler-6.jpg"));
      this.imgs1.push(require("../assets/galler-7.jpg"));
      this.imgs1.push(require("../assets/galler-8.jpg"));
      this.imgs1.push(require("../assets/galler-9.jpg"));
      this.imgs1.push(require("../assets/galler-10.jpg"));
      this.imgs1.push(require("../assets/galler-11.jpg"));
      this.imgs1.push(require("../assets/galler-12.jpg"));



    this.imgs.push({"pic1":require("../assets/galler-1.jpg"), "pic2":require("../assets/galler-2.jpg")});
    this.imgs.push({"pic1":require("../assets/galler-3.jpg"), "pic2":require("../assets/galler-4.jpg")});
    this.imgs.push({"pic1":require("../assets/galler-5.jpg"), "pic2":require("../assets/galler-6.jpg")});
    this.imgs.push({"pic1":require("../assets/galler-7.jpg"), "pic2":require("../assets/galler-8.jpg")});
    this.imgs.push({"pic1":require("../assets/galler-9.jpg"), "pic2":require("../assets/galler-10.jpg")});
    this.imgs.push({"pic1":require("../assets/galler-11.jpg"), "pic2":require("../assets/galler-12.jpg")});
    
    },

  
    showImg(index) {
      console.log(index)
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },
};
</script>
<style scoped>
.gallery-area{background-image: url(../assets/gallery-bg.png);
    background-repeat: no-repeat; background-position: center;
    background-size: cover;padding: 100px 0 80px 0;
    margin: 20px 0 0 0;
}
.pic img{width: 100%; margin-bottom: 25px; border-radius: 20px;}
/* .pic img:hover{transform: scale(1.5);} */
.mt-80{margin-top: 80px;}
.mt-160{margin-top: 160px;}
.gcontainer{position: relative;
  width: 100%;}
  .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; 
  width: 100%;
  opacity: 0;
  transition: .3s ease;
  cursor: pointer;
}

.gcontainer:hover .overlay {
  opacity: 1;
  
}

.overlay svg {
  color: white;
  text-shadow: 5px 0px 1px #000;
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.overlay svg.one {  
  top: 60%;  
}
.overlay svg.two {  
  top: 70%;  
}
h2{font-size: 3.125rem;line-height: 1em; transform: rotate(0deg); text-align: center; font-weight: bold; color: #000; margin-top: 0px;}
.elementor-icon {
    vertical-align: middle;
    max-width: 110px;
    max-height: 110px;color: #00832e;
    border-color: #00832e;margin:0 auto 0px auto;
}
.mt{padding: 100px 0 0 0;}
.p{font-size: 22px; font-weight: bold;color: #00832e;}
@media (max-width: 991.98px) {
.mt-80{margin-top: 0px;}
.mt-160{margin-top: 0px;}
.overlay svg.one { top: 50%;}
.overlay svg.two { top: 50%;}.elementor-icon {margin:0 auto 30px auto;}
}


</style>