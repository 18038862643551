<template>
  <header>
    <b-container class="bv-example-row">
      <b-row align-h="between">
        <b-col cols="9" col xl="4" lg="6">
            <ul>
                <li><font-awesome-icon icon="phone" /><a href="tel:+91 9004384358">+91 9004384358</a></li>
                <li><font-awesome-icon icon="envelope" /> <a href="mailto:chirpychirpstay@gmail.com">chirpychirpstay@gmail.com</a></li>
            </ul>
        </b-col>
        <b-col cols="3" col xl="1" lg="1">
            <ul>
                <li><a href="https://www.facebook.com/" target="blank"><font-awesome-icon :icon="{prefix: 'fab', iconName: 'facebook-f'}" /></a></li>
                <li><a href="https://www.instagram.com/chirpychirpstay/" target="blank"><font-awesome-icon :icon="{prefix: 'fab', iconName: 'instagram'}"  /></a></li>
            </ul>
        </b-col>
      </b-row>
    </b-container>
  </header>

 
</template>



<script>
export default {
  
  name: "TopArea",
  props: {
    msg: String,
  },
};
</script>


<style scoped>
header {  background: #00832e;  padding: 10px 0;  color: #fff; font-size: 14px;}
header ul{margin: 0; padding: 0;} header ul li{float: left; margin-right: 15px; list-style: none;}header ul li:last-child{margin-right: 0px;}
header ul li svg{margin-right: 5px; font-size: 15px; line-height: 30px;} header ul li a{color: #fff; text-decoration: none;}
</style>