<template>
   <section class="contact-area container">
       <p class="text-center">Get in Touch</p>
       <h2>Contact Us</h2>
        <div class="elementor-icon">
            <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 53 7" xml:space="preserve">
            <g class="nc-icon-wrapper"><path class="st0" d="M0 2.59h.44c.77 0 2.82-2.67 4.58.96 1.23 2.53 3.66 2.4 5.31 0 1.65-2.4 4.26-2.7 6.53 0 1.67 1.99 3.83 2.86 5.53 0 .45-.75 3-3.96 6.03 0 3.17 4.15 5.83 0 5.83 0s2.68-4.33 5.86 0c3.02 4.11 5.25 0 5.25 0s2.31-4.32 5.12 0C51.2 4.64 53 4.56 53 4.56" fill="none" stroke="currentColor" stroke-width="2" stroke-miterlimit="100"></path></g>
            </svg>
        </div>
    <b-container fluid>
        <b-row class="justify-content-lg-center">
          
           <b-col sm="12" md="12" lg="6" cols="12">
               <div class="get-touch-area" cols="12">
                   <h3>Get in Touch</h3>
                    <div class="get-touch">
                        <b-row class="justify-content-lg-center">
                            <b-col col cols="2" lg="2" md="1"><font-awesome-icon icon="location-dot" /></b-col>
                            <b-col cols="10"  lg="10"><h4>Address</h4>
                                4B-42 kalpataru estate, Poonam Nagar, Andheri East, Mumbai, Maharashtra 400093</b-col>
                        </b-row>
                    </div>

                     <div class="get-touch">
                        <b-row class="justify-content-lg-center">
                            <b-col col cols="2" lg="2" md="1"><font-awesome-icon icon="phone" /></b-col>
                            <b-col cols="10"  lg="10">
                                <h4>Telephone</h4>
                                +91 9004384358</b-col>
                        </b-row>
                    </div>

                    <div class="get-touch">
                        <b-row class="justify-content-lg-center">
                            <b-col cols="2" col lg="2" md="1"><font-awesome-icon icon="envelope" /></b-col>
                            <b-col cols="10" md="6" lg="10"><h4>Email</h4>
                                chirpychirpstay@gmail.com</b-col>
                        </b-row>
                    </div>
                   
                    
               </div>
           </b-col>
           <b-col lg="6">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.3256541703854!2d72.86598491444!3d19.137217287052838!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b7fcb5ebfd8b%3A0x4031afdc51202e67!2sChirpyChirps!5e0!3m2!1sen!2sin!4v1650157480884!5m2!1sen!2sin" width="100%" height="500" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
           </b-col>
        </b-row>
    </b-container>
   </section>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {required,email} from '@vuelidate/validators'



export function validName(name) {
  if (name!=""){
    return true;
  }
  return false;
}

export default {  
  name: "CountUs", 

  methods: {
    sendEmail() {
    //  console.log("hhhhhhhhhhhhh")
  


    }
  },

 setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      form: {
        name: '',
        email: '',
        subject: '',
        message: '',
       
      }
    }
  },
  validations() {
    return {
      form: {
        name: { 
            required, 
            name_validation: {
            $validator: validName,
            $message: 'Invalid Name. Valid name only contain letters, dashes (-) and spaces'
          } 
        },
      email: { required, email },
      subject: { 
                  required, 
                  name_validation: {
                  $validator: validName,
                  $message: 'Invalid Subject'
                } 
              },
     
      
      },
    }
  },



};
</script>

<style scoped>

.contact-area p{font-size: 22px; font-weight: bold;color: #00832e;}
.contact-area h2{font-size: 3.125rem;line-height: 1em; transform: rotate(0deg); text-align: center; font-weight: bold; color: #000;margin-top: 0px;}
.elementor-icon {
    vertical-align: middle;
    max-width: 110px;
    max-height: 110px;color: #00832e;
    border-color: #00832e;margin:0 auto 30px auto ;
}
.contact-box{background: #00832e; border-radius: 10px; padding: 40px; z-index: 1; color: #fff; margin: 20% 0 0 0; border: 0px;}
.contact-box h3{font-weight: bold; font-size: 36x;}
.input{margin: 10px 0; height: 40px; background: rgba(255, 255, 255); border: 1px solid #fff; color: #000; width: 100%; padding: 0 0 0 10px;}
textarea {margin: 10px 0;height:150px; background: rgba(255, 255, 255); border: 1px solid #fff; color: #000; width: 100%; padding: 0 0 0 10px;}
.btnsubmit{background: rgb(0, 173, 58); padding: 10px 30px; margin: 10px 0 0 0;  color: #fff; }
.get-touch-area{padding: 70px 20px 0 50px;}
.get-touch-area h3{font-weight: bold; color: #393d72; font-size: 36px; margin-bottom: 30px;}
.get-touch {margin-bottom: 30px; font-size: 18px; color: #858687;}
.get-touch h4{font-weight: bold; color: #393d72;}
.get-touch svg{font-size: 40px; color: #00832e;}

@media (min-width: 320px) and (max-width: 575.98px) { 
   .contact-area img{width: 100%;} .get-touch-area{padding: 20px;}.contact-box{margin-top: 5%;}
}
 @media (min-width: 576px) and (max-width: 767.98px) {
    .contact-area img{width: 100%;}.get-touch-area{padding: 20px;}.contact-box{margin-top: 5%;}
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
      .contact-area img{width: 100%;}.get-touch-area{padding: 20px;}.contact-box{margin-top: 5%;}
   }

    @media (min-width: 992px) and (max-width: 1199.98px) {.get-touch-area{padding: 20px;} 
    .contactimg{width: 100%;}}

</style>